import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a>react-redux</a>
                                                </li>
                                                <li>
                                                    <a>python</a>
                                                </li>
                                                <li>
                                                    <a>postgreSQL</a>
                                                </li>
                                                <li>
                                                    <a>git</a>
                                                </li>
                                                <li>
                                                    <a>SASS</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a>Certificate in Programming<span>- BCS</span></a> May 2019
                                               </li>
                                               <li>
                                                   <a>Software Development and Methodologies<span>- BCS</span></a> April 2019
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a>Software Engineer<span> - BKwai</span></a> 2020 - Present
                                               </li>
                                               <li>
                                                   <a>Junior Full Stack Developer<span> - Digital Fineprint</span></a> 2018 - 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a>Software Development Technician <span> - Distinction</span></a> 2020
                                               </li>
                                               <li>
                                                   <a>Computer Science, Electronics and Math<span> - City & Islington College</span></a> 2018
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;